import React from 'react';
import css from './ModalMissingInformation.module.css';
import NamedLink from '../NamedLink/NamedLink';
const SignupPop = () => {
  return (
    <div className={css.popupContainer}>
      <h3 className={css.popupHeading}>Sign up and start your Creator /Brand Journey!</h3>
      <NamedLink name="SignupPage" className={css.signupLink}>
        Click here
      </NamedLink>
    </div>
  );
};

export default SignupPop;
